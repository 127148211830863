import axios from 'axios'
import { API_GATEWAY } from './rotasServidor'

export const atualizarEmpresa = async (cliente) => await axios.post(`${API_GATEWAY}/geral/atualizar/empresa`, cliente)

//.....................Início: Usuário .......................

export const getListaUsuario = async function(filtro) {
  let query = '/';
  let simbolo = "?";


  if (filtro.idUser != '' && filtro.idUser !== undefined) {
      query = `${query}${simbolo}idUser=${filtro.idUser}`;
      simbolo = "&";
  }

  if (filtro.nome !== '' && filtro.nome !== undefined) {
      query = `${query}${simbolo}nome=${filtro.nome}`;
      simbolo = "&";
  }

  if (filtro.login !== '' && filtro.login !== undefined) {
      query = `${query}${simbolo}login=${filtro.login}`;
      simbolo = "&";
  }
  
  if (filtro.projeto !== '' && filtro.projeto !== undefined) {
      query = `${query}${simbolo}projeto=${filtro.projeto}`;
      simbolo = "&";
  }

  if (filtro.token !== '' && filtro.token !== undefined) {
      query = `${query}${simbolo}token=${filtro.token}`;
      simbolo = "&";
  }

  if (filtro.limite !== '') {
      query = `${query}${simbolo}limite=${filtro.limite}`
      simbolo = "&";
  } else {
      query = `${query}${simbolo}limite=0`
      simbolo = "&";
  }

  if (filtro.pagina !== '') {
      query = `${query}${simbolo}pagina=${filtro.pagina}`
      simbolo = "&";
  } else {
      query = `${query}${simbolo}pagina=0`
      simbolo = "&";
  }
  
  return axios.get(`${API_GATEWAY}/geral/usuario${query}`);
}

export const addUsuario = async function(body) {
  return axios.post(`${API_GATEWAY}/geral/usuario`, body);
}

export const updUsuario = async function(body) {
  return axios.put(`${API_GATEWAY}/geral/usuario`, body);
}

export const delUsuario = async function(id) {
  let query = '';

  if (id !== undefined) {
      query = `/?id=${id}`;
  }

  return axios.delete(`${API_GATEWAY}/geral/usuario${query}`);
}
//........................Fim: Usuário ...........................